export default [
  {
    header: 'menu.properties.header',
    resource: 'create_property' || 'all_properties',
    action: 'read',
  },

  /*
    *
    *  Menu Items
    * */
  {
    title: 'menu.properties.children.all_properties',
    route: 'all_properties',
    resource: 'all_properties',
    action: 'read',
    icon: 'GridIcon',
  },
  {
    title: 'menu.properties.children.new_property',
    route: 'new_property',
    resource: 'create_property',
    action: 'read',
    icon: 'PlusIcon',
  },
]
